import { template as template_b366e86c0738434ca7e2e77fcaf882a5 } from "@ember/template-compiler";
const FKText = template_b366e86c0738434ca7e2e77fcaf882a5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_b4c34758451942d499f6347c13465044 } from "@ember/template-compiler";
const FKLabel = template_b4c34758451942d499f6347c13465044(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

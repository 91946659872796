import { template as template_e9698a9baeeb4b1ca7ad28d7930bc433 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e9698a9baeeb4b1ca7ad28d7930bc433(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
